import Vue from "vue";
import Router from "vue-router";
import VueI18n from "../lang/index";

// Containers
const Container = () => import("@/containers/Container");
const ContainerAdmin = () => import("@/containers/ContainerAdmin");

// Views
const Login = () => import("@/views/threespace/Login");
const Join = () => import("@/views/threespace/Join");
const Agreement = () => import("@/views/threespace/Agreement");
const JoinTerms = () => import("@/views/threespace/JoinTerms");
const FindAccount = () => import("@/views/threespace/FindAccount");
const ResetPassword = () => import("@/views/threespace/ResetPassword");

const Home = () => import("@/views/threespace/Home");
const Drops = () => import("@/views/threespace/Drops");
const Ordinals = () => import("@/views/threespace/Ordinals");
const AIArt = () => import("@/views/threespace/AIArt");
const Meme = () => import("@/views/threespace/Meme");
const Artwork = () => import("@/views/threespace/Artwork");
const Artists = () => import("@/views/threespace/Artists");
const Artist = () => import("@/views/threespace/Artist");
const About = () => import("@/views/threespace/About");
// const MyPage = () => import("@/views/threespace/MyPage");
const MyPageDetail = () => import("@/views/threespace/MyPageDetail");
const MyPageUpdate = () => import("@/views/threespace/MyPageUpdate");
const IdentityVerify = () => import("@/views/threespace/IdentityVerify");
const PaymentList = () => import("@/views/threespace/mypage/PaymentList");
const FeatureList = () => import("@/views/threespace/features/FeatureList");
const FeatureDetail = () => import("@/views/threespace/features/FeatureDetail");
const Apply = () => import("@/views/threespace/Apply");
const Payment = () => import("@/views/threespace/Payment");
const PaymentCardMobile = () => import("@/views/threespace/payment/PaymentCardMobile");
const Terms = () => import("@/views/threespace/Terms");
const Event = () => import("@/views/threespace/Event");
const Guide = () => import("@/views/threespace/Guide");
const Contact = () => import("@/views/threespace/Contact");
const Claim = () => import("@/views/threespace/Claim");
const Collections = () => import("@/views/threespace/Collections");
const ArtPool = () => import("@/views/threespace/ArtPool");
const NftPool = () => import("@/views/threespace/artpool/NftPool");
const NftPoolDetail = () => import("@/views/threespace/artpool/NftPoolDetail");
const NftPoolStake = () => import("@/views/threespace/artpool/NftPoolStake");
const NftPoolVote = () => import("@/views/threespace/artpool/NftPoolVote");
const NftPoolGallery = () => import("@/views/threespace/artpool/NftPoolGallery");
const PacePool = () => import("@/views/threespace/PacePool");

// 신한카드 꼬마피카소 갤러리
const PicassoGallery = () => import("@/views/threespace/picasso/PicassoGallery");
const PicassoDetail = () => import("@/views/threespace/picasso/PicassoDetail");


// 이벤트
const Hashmask = () => import("@/views/threespace/event/Hashmask");

// Management
const ProductList = () => import("@/views/management/product/ProductList");
const ProductSave = () => import("@/views/management/product/ProductSave");
const ProductDetail = () => import("@/views/management/product/ProductDetail");
const ProductNFT = () => import("@/views/management/product/ProductNFT");

const CompanyList = () => import("@/views/management/company/CompanyList");
const CompanySave = () => import("@/views/management/company/CompanySave");
const ArtistList = () => import("@/views/management/artist/ArtistList");
const ArtistSave = () => import("@/views/management/artist/ArtistSave");
const MngArtistDetail = () => import("@/views/management/artist/ArtistDetail");
const OrderList = () => import("@/views/management/order/OrderList");
const OrderDetail = () => import("@/views/management/order/OrderDetail");
const MemberList = () => import("@/views/management/member/MemberList");
const MemberSave = () => import("@/views/management/member/MemberSave");
const CodeList = () => import("@/views/management/code/CodeList");
const CodeSave = () => import("@/views/management/code/CodeSave");
const ArticleList = () => import("@/views/management/article/ArticleList");
const ArticleDetail = () => import("@/views/management/article/ArticleDetail");
const ArticleSave = () => import("@/views/management/article/ArticleSave");
const ArtpoolList = () => import("@/views/management/artpool/ArtpoolList");
const ArtpoolDetail = () => import("@/views/management/artpool/ArtpoolDetail");
const ArtpoolSave = () => import("@/views/management/artpool/ArtpoolSave");
const ArtpoolRound = () => import("@/views/management/artpool/ArtpoolRound");
const ArtpoolVote = () => import("@/views/management/artpool/ArtpoolVote");
const ArtpoolWhitelist = () => import("@/views/management/artpool/ArtpoolWhitelist");
const ArtpoolCollection = () => import("@/views/management/artpool/ArtpoolCollection");
const NftList = () => import("@/views/management/nft/NftList");
const NftSave = () => import("@/views/management/nft/NftSave");
const CollectionList = () => import("@/views/management/collection/CollectionList");
const CollectionDetail = () => import("@/views/management/collection/CollectionDetail");

// Corporation
const CorpArtworkList = () => import("@/views/corporation/artwork/CorpArtworkList");

// Common
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");

Vue.use(Router);

const setLocale = () => (to, from, next) => {
  const _lang = to.query.lang;
  if (_lang !== undefined && _lang !== null && _lang !== "" && (_lang === "ko" || _lang === "en")) {
    const lang = localStorage.getItem("language");
    if (_lang !== lang) {
      VueI18n.locale = _lang;
      localStorage.setItem("language", _lang);
    }
  }
  return next();
};

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      component: Container,
      children: [
        {
          path: "",
          name: "Prepare",
          component: Home,
          beforeEnter: setLocale()
        },
        {
          path: "home",
          name: "Home",
          component: Home
        },
        {
          path: "home/:referral",
          props: true,
          name: "ReferralHome",
          component: Home
        },
        {
          path: "drops",
          name: "Drops",
          component: Drops
        },
        {
          path: "ordinals",
          name: "Ordinals",
          component: Ordinals
        },
        {
          path: "ai",
          name: "AIArt",
          component: AIArt
        },
        {
          path: "meme",
          name: "Meme",
          component: Meme
        },
        {
          path: "art/:idxProduct",
          props: true,
          name: "Artwork",
          component: Artwork
        },
        {
          path: "art/:idxProduct/:idxCompany",
          props: true,
          name: "CorpArtwork",
          component: Artwork
        },
        {
          path: "artist",
          name: "Artists",
          component: Artists
        },
        {
          path: "artist/:idxArtist",
          props: true,
          name: "Artist",
          component: Artist
        },
        {
          path: "payment/:idxProduct",
          props: true,
          name: "Payment",
          component: Payment
        },
        {
          path: "payment-card-mobile",
          name: "PaymentCardMobile",
          component: PaymentCardMobile
        },
        {
          path: "about",
          name: "About",
          component: About
        },
        // {
        //   path: "features",
        //   name: "Features",
        //   component: Features
        // },
        // {
        //   path: "features-detail/:pageType",
        //   name: "FeaturesDetail",
        //   props: true,
        //   component: FeaturesDetail
        // },
        {
          path: "features",
          name: "FeatureList",
          component: FeatureList
        },
        {
          path: "features/:idx",
          name: "FeatureDetail",
          props: true,
          component: FeatureDetail
        },
        {
          path: "apply",
          name: "Apply",
          component: Apply
        },
        {
          path: "mypage",
          redirect: "mypage/detail",
          name: "MyPage",
          props: true,
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: "payment-list",
              name: "PaymentList",
              component: PaymentList
            },
            {
              path: "detail",
              name: "MyPageDetail",
              props: true,
              component: MyPageDetail
            },
            {
              path: "update",
              name: "MyPageUpdate",
              props: true,
              component: MyPageUpdate
            }
          ]
        },
        {
          path: "terms/:code",
          name: "Terms",
          props: true,
          component: Terms
        },
        {
          path: "join-terms",
          name: "JoinTerms",
          props: true,
          component: JoinTerms
        },
        {
          path: "event",
          name: "Event",
          component: Event
        },
        {
          path: "hashmask/:no",
          name: "Hashmask",
          props: true,
          component: Hashmask
        },
        {
          path: "guide",
          name: "Guide",
          component: Guide
        },
        {
          path: "contact",
          name: "Contact",
          component: Contact
        },
        {
          path: "claim",
          name: "Claim",
          component: Claim
        },
        {
          path: "collections/:idxEncode",
          props: true,
          name: "Collections",
          component: Collections
        },
        {
          path: "art-pool",
          redirect: "art-pool/nft",
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: "pace2.0",
              name: "PacePool",
              component: PacePool,
            },
            {
              path: "pace",
              name: "ArtPool",
              component: ArtPool,
            },
            {
              path: "nft",
              name: "NftPool",
              component: NftPool
            },
            {
              path: "nft/detail/:idxArtpool",
              name: "NftPoolDetail",
              props: (route) => {
                const idxArtpool = Number.parseInt(route.params.idxArtpool, 10)
                if (Number.isNaN(idxArtpool)) {
                  return 0
                }
                return { idxArtpool }
              },
              component: NftPoolDetail
            },
            {
              path: "nft/stake/:idxArtpool",
              name: "NftPoolStake",
              props: (route) => {
                const idxArtpool = Number.parseInt(route.params.idxArtpool, 10)
                if (Number.isNaN(idxArtpool)) {
                  return 0
                }
                return { idxArtpool }
              },
              component: NftPoolStake
            },
            {
              path: "nft/vote/:idxArtpool",
              name: "NftPoolVote",
              props: (route) => {
                const idxArtpool = Number.parseInt(route.params.idxArtpool, 10)
                if (Number.isNaN(idxArtpool)) {
                  return 0
                }
                return { idxArtpool }
              },
              component: NftPoolVote
            },
            {
              path: "nft/gallery",
              name: "NftPoolGallery",
              component: NftPoolGallery
            },
            {
              path: "nft/gallery/:referral",
              name: "ReferralNftPoolGallery",
              props: true,
              component: NftPoolGallery
            }
          ]
        },
        {
          path: "little-picasso",
          redirect: "little-picasso/gallery",
          name: "Picasso",
          props: true,
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: "gallery",
              name: "PicassoGallery",
              props: true,
              component: PicassoGallery
            },
            {
              path: "detail/:idxCollection",
              name: "PicassoDetail",
              props: true,
              component: PicassoDetail
            }
          ]
        },
      ]
    },
    {
      path: "/management",
      redirect: "/management/product-list",
      name: "Management",
      component: ContainerAdmin,
      children: [
        {
          path: "product-list",
          name: "ProductList",
          props: true,
          component: ProductList
        },
        {
          path: "product-save/:idxProduct",
          name: "ProductSave",
          props: true,
          component: ProductSave
        },
        {
          path: "product-detail/:idxProduct",
          name: "ProductDetail",
          props: true,
          component: ProductDetail
        },
        {
          path: "product-nft/:idxProduct",
          name: "ProductNFT",
          props: true,
          component: ProductNFT
        },
        {
          path: "company-list",
          name: "CompanyList",
          component: CompanyList
        },
        {
          path: "company-save/:idxCompany",
          name: "CompanySave",
          props: true,
          component: CompanySave
        },
        {
          path: "artist-list",
          name: "ArtistList",
          component: ArtistList
        },
        {
          path: "artist-save/:idxArtist",
          name: "ArtistSave",
          props: true,
          component: ArtistSave
        },
        {
          path: "artist-detail/:idxArtist",
          name: "MngArtistDetail",
          props: true,
          component: MngArtistDetail
        },
        {
          path: "order-list",
          name: "OrderList",
          component: OrderList
        },
        {
          path: "order-detail/:idxOrderPayment",
          name: "OrderDetail",
          props: true,
          component: OrderDetail
        },
        {
          path: "member-list",
          name: "MemberList",
          component: MemberList
        },
        {
          path: "member-save/:idxMember",
          name: "MemberSave",
          props: true,
          component: MemberSave
        },
        {
          path: "code-list",
          name: "CodeList",
          component: CodeList
        },
        {
          path: "code-save/:idxCode",
          name: "CodeSave",
          props: true,
          component: CodeSave
        },
        {
          path: "article-list",
          name: "ArticleList",
          component: ArticleList
        },
        {
          path: "article-detail/:idxArticle",
          name: "ArticleDetail",
          props: true,
          component: ArticleDetail
        },
        {
          path: "article-save/:idxArticle",
          name: "ArticleSave",
          props: true,
          component: ArticleSave
        },
        {
          path: "artpool-list",
          name: "ArtpoolList",
          props: true,
          component: ArtpoolList
        },
        {
          path: "artpool-detail/:idxArtpool",
          name: "ArtpoolDetail",
          props: true,
          component: ArtpoolDetail
        },
        {
          path: "artpool-save/:idxArtpool",
          name: "ArtpoolSave",
          props: true,
          component: ArtpoolSave
        },
        {
          path: "artpool-round/:idxArtpool",
          name: "ArtpoolRound",
          props: true,
          component: ArtpoolRound
        },
        {
          path: "artpool-vote/:idxArtpool",
          name: "ArtpoolVote",
          props: true,
          component: ArtpoolVote
        },
        {
          path: "artpool-whitelist/:idxArtpool",
          name: "ArtpoolWhitelist",
          props: true,
          component: ArtpoolWhitelist
        },
        {
          path: "artpool-collection/:idxArtpool",
          name: "ArtpoolCollection",
          props: true,
          component: ArtpoolCollection
        },
        {
          path: "nft-list",
          name: "NftList",
          component: NftList
        },
        {
          path: "nft-save/:idxNft",
          name: "NftSave",
          props: true,
          component: NftSave
        },
        {
          path: "collection-list",
          name: "CollectionList",
          component: CollectionList
        },
        {
          path: "collection-detail/:idxCollection",
          name: "CollectionDetail",
          props: true,
          component: CollectionDetail
        }
      ]
    },
    {
      path: "/corp-management",
      redirect: "/corp-management/artworks",
      name: "CorpManagement",
      component: ContainerAdmin,
      children: [
        {
          path: "artworks",
          name: "CorpArtworkList",
          component: CorpArtworkList
        },
        {
          path: "artist-list",
          name: "CorpArtistList",
          component: ArtistList
        },
        {
          path: "artist-save/:idxArtist",
          name: "CorpArtistSave",
          props: true,
          component: ArtistSave
        },
        {
          path: "artist-detail/:idxArtist",
          name: "CorpArtistDetail",
          props: true,
          component: MngArtistDetail
        }
      ]
    },
    {
      path: "/",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login
        },
        {
          path: "join",
          name: "Join",
          props: true,
          component: Join
        },
        {
          path: "agreement",
          name: "Agreement",
          component: Agreement
        },
        {
          path: "find-account",
          name: "FindAccount",
          component: FindAccount
        },
        {
          path: "reset-password",
          name: "ResetPassword",
          component: ResetPassword
        },
        {
          path: "identity-verify",
          name: "IdentityVerify",
          component: IdentityVerify
        }
      ]
    },
    {
      path: "/error",
      redirect: "/error/404",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404
        },
        {
          path: "500",
          name: "Page500",
          component: Page500
        }
      ]
    },
    {
      path: "*",
      redirect: "/error/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: Page404
    }
  ]
});
